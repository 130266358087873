import React from "react"
import { ButtonRadios } from "../ButtonRadios"
import { FormControl, formControlPadding } from "../FormControl"

interface EmploymentStatusFormControlProps {
  employmentStatus: string
  setEmploymentStatus: (value: string) => void
}

const employmentStatusButtons = [
  {
    value: "casual",
    display: "Casual"
  },
  {
    value: "permanent",
    display: "Permanent"
  }
]

export const EmploymentStatusFormControl = ({
  employmentStatus,
  setEmploymentStatus
}: EmploymentStatusFormControlProps) => (
  <FormControl overrides={formControlPadding} label="Employment Status?">
    <ButtonRadios
      mappings={employmentStatusButtons}
      currentValue={employmentStatus}
      updateFunction={setEmploymentStatus}
    />
  </FormControl>
)
