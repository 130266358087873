import React from "react"
import { FormControl, formControlPadding } from "../FormControl"
import { FormControlOverrides } from "baseui/form-control"
import { Datepicker } from "../Datepicker"

interface WhenDidYouFinishFormControlProps {
  finishDate: Date | null
  setFinishDate: (value: Date) => void
}

const dateOverrides: FormControlOverrides = {
  ...formControlPadding,
  ControlContainer: {
    style: {
      maxWidth: "140px"
    }
  }
}

export const WhenDidYouFinishFormControl = ({
  finishDate,
  setFinishDate
}: WhenDidYouFinishFormControlProps) => (
  <FormControl label="When did you finish this job?" overrides={dateOverrides}>
    <Datepicker
      value={finishDate}
      onChange={({ date }) => {
        setFinishDate(date as Date)
      }}
      formatString={"dd/MM/yyyy"}
      mask={"99/99/9999"}
      placeholder={"DD/MM/YYYY"}
      maxDate={new Date()}
    />
  </FormControl>
)
