import React from "react"
import { Layout } from "../Layout"
import { Cell } from "baseui/layout-grid"
import { Button } from "../Button"
import { CurrentJobFormControl } from "./CurrentJobFormControl"
import { WhenDidYouFinishFormControl } from "./WhenDidYouFinishFormControl"
import { EmploymentStatusFormControl } from "./EmploymentStatusFormControl"
import { AgeFormControl } from "./AgeFormControl"
import { Paragraph3 } from "baseui/typography"
import { HourlyPayFormControl } from "./HourlyPayFormControl"

export const dateIsYearsAgo = (date: Date, years: number) => {
  const twoYearsAgo = new Date(Date.now())

  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - years)
  if (twoYearsAgo > date) {
    return true
  } else {
    return false
  }
}

interface QuickEstimateFormProps {
  finishDate?: Date
}

export const QuickEstimateForm = (props: QuickEstimateFormProps) => {
  const [currentJob, setCurrentJob] = React.useState("")
  const [employmentStatus, setEmploymentStatus] = React.useState("")
  const [age, setAge] = React.useState("")
  const [finishDate, setFinishDate] = React.useState<Date | null>(
    props.finishDate || null
  )

  const showFinishQuestion = currentJob === "no"
  const dateIsTwoYearsAgo = finishDate && dateIsYearsAgo(finishDate, 2)
  const showRestOfTheQuestions =
    (showFinishQuestion && finishDate && !dateIsYearsAgo(finishDate, 2)) ||
    currentJob === "yes"
  const showTooOld = dateIsTwoYearsAgo && !showRestOfTheQuestions

  return (
    <Layout heading="First, tell us about your working situation.">
      <Cell span={[4, 8, 12]}>
        <CurrentJobFormControl
          currentJob={currentJob}
          setCurrentJob={setCurrentJob}
        />
        {showFinishQuestion && (
          <WhenDidYouFinishFormControl
            setFinishDate={setFinishDate}
            finishDate={finishDate}
          />
        )}
        {showRestOfTheQuestions && (
          <>
            <EmploymentStatusFormControl
              employmentStatus={employmentStatus}
              setEmploymentStatus={setEmploymentStatus}
            />{" "}
            <AgeFormControl age={age} setAge={setAge} />
            <HourlyPayFormControl />
          </>
        )}
        {showTooOld && (
          <Paragraph3>
            <strong>Seeing as your claim is over two years old</strong> Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales
            vulputate purus sed convallis. Nam egestas, libero in suscipit
            facilisis, lectus risus consectetur dui, sed luctus sapien eros vel
            lorem.
          </Paragraph3>
        )}
      </Cell>
      <Cell span={[2, 2, 2]}>{!showTooOld && <Button>Calculate</Button>}</Cell>
    </Layout>
  )
}
