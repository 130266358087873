import {
  Button as BaseButton,
  ButtonProps,
  ButtonOverrides
} from "baseui/button"
import React from "react"
import withOverrides from "./overrides"
import { LinkProps, useHistory } from "react-router-dom"

import { KIND, SIZE, SHAPE } from "baseui/button"
import { StyleObject } from "styletron-react"
import { Theme } from "baseui/theme"
import { dustyGray } from "./colors"
export interface SharedStylePropsT {
  $kind?: keyof KIND
  $isSelected?: boolean
  $shape?: keyof SHAPE
  $size?: keyof SIZE
  $isLoading?: boolean
  $disabled?: boolean
  $isFocusVisible: boolean
  $theme: Theme
}
const globalOverrides: ButtonOverrides = {
  BaseButton: {
    style: (props: SharedStylePropsT) => {
      let style: StyleObject = {
        marginLeft: "0",
        marginRight: "0",
        paddingTop: "12px",
        paddingBottom: "12px",
        display: "block",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px"
      }
      if (props.$kind === KIND.primary) {
        return {
          ...style,
          marginTop: "32px",
          marginBottom: "32px",
          paddingLeft: "36px",
          paddingRight: "36px",
          fontWeight: "bold",
          display: "block"
        }
      } else if (props.$kind === KIND.secondary) {
        style = {
          ...style,
          marginLeft: "0",
          marginRight: "0",
          maxWidth: "120px",
          flexGrow: 1,
          paddingLeft: "4px",
          paddingRight: "4px",
          paddingTop: "12px",
          paddingBottom: "12px",
          fontWeight: "normal",
          display: "block",
          borderTopColor: dustyGray,
          borderBottomColor: dustyGray,
          borderRightColor: dustyGray,
          borderLeftColor: dustyGray,
          borderLeftWidth: "1px",
          borderRightWidth: "1px",
          borderTopWidth: "1px",
          borderBottomWidth: "1px",
          borderLeftStyle: "solid",
          borderRightStyle: "none",
          borderTopStyle: "solid",
          borderBottomStyle: "solid"
        }
        if (props.$isSelected) {
          style = {
            ...style,
            borderTopColor: props.$theme.colors.buttonSecondarySelectedFill,
            borderBottomColor: props.$theme.colors.buttonSecondarySelectedFill,
            borderRightColor: props.$theme.colors.buttonSecondarySelectedFill,
            borderLeftColor: props.$theme.colors.buttonSecondarySelectedFill
          }
        }
      }
      return style
    }
  }
}

export const Button = withOverrides<ButtonProps>(BaseButton, globalOverrides)

type LinkWithoutType = Omit<LinkProps, "type" | "onClick">
interface LinkButtonProps extends ButtonProps {
  to: string
}

export const LinkButton = ({ to, ...buttonProps }: LinkButtonProps) => {
  const { push } = useHistory()
  return (
    <Button
      {...buttonProps}
      onClick={() => {
        push(to)
      }}
    ></Button>
  )
}
