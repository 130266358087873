import React from "react"
import { ButtonRadios } from "../ButtonRadios"
import { FormControl, formControlPadding } from "../FormControl"

interface AgeFormControlProps {
  age: string
  setAge: (value: string) => void
}

const ageButtons = [
  {
    value: "16",
    display: "16"
  },
  {
    value: "17",
    display: "17"
  },
  {
    value: "18",
    display: "18"
  },
  {
    value: "19",
    display: "19"
  },
  {
    value: "20",
    display: "20"
  },
  {
    value: "21+",
    display: "21 and older"
  }
]
export const AgeFormControl = ({ age, setAge }: AgeFormControlProps) => (
  <FormControl overrides={formControlPadding} label="Your age (years)">
    <ButtonRadios
      mappings={ageButtons}
      currentValue={age}
      updateFunction={setAge}
    />
  </FormControl>
)
