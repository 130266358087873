import React from "react"
import { ButtonRadios } from "../ButtonRadios"
import { FormControl } from "../FormControl"

interface CurrentJobFormControlProps {
  currentJob: string
  setCurrentJob: (value: string) => void
}

const yesNoButtons = [
  {
    value: "yes",
    display: "Yes"
  },
  {
    value: "no",
    display: "No"
  }
]
export const CurrentJobFormControl = ({
  currentJob,
  setCurrentJob
}: CurrentJobFormControlProps) => (
  <FormControl label="Is this enquiry for your current job?">
    <ButtonRadios
      mappings={yesNoButtons}
      currentValue={currentJob}
      updateFunction={setCurrentJob}
    />
  </FormControl>
)
