import { Input as BaseInput, InputProps, InputOverrides } from "baseui/input"
import withOverrides from "./overrides"
import { silver } from "./colors"

export const globalOverrides: InputOverrides = {
  InputContainer: {
    style: {
      borderColor: silver,
      borderStyle: "solid",
      borderWidth: "1px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      boxShadow: "inset 1px 1px 3px 0 rgba(0,0,0,0.5)"
    }
  }
}

export const Input = withOverrides<InputProps>(BaseInput, globalOverrides)
