import React from "react"
import { useStyletron } from "baseui"
import { Grid, CellProps, Cell } from "baseui/layout-grid"
import { wildSand } from "./colors"

type CellChildren =
  | React.ReactElement<CellProps>
  | React.ReactElement<CellProps>[]
export const Layout = ({
  children,
  heading
}: {
  children: CellChildren
  heading: string
}) => {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        background: wildSand,
        paddingTop: "16px",
        marginTop: "32px"
      })}
    >
      <Grid gridMargins={[16, 24, 24]}>
        <Cell span={[4, 8, 12]}>
          <h3
            className={css({
              fontFamily: '"Glypha LT W01 55 Roman",Helvetica,Arial,sans-serif',
              fontSize: "24px",
              paddingBottom: "8px"
            })}
          >
            {heading}
          </h3>
        </Cell>
      </Grid>
      <Grid gridMargins={[16, 24, 24]}>{children}</Grid>
    </div>
  )
}
