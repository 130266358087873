import React from "react"
import { Layout } from "./Layout"
import { Cell } from "baseui/layout-grid"
import { Paragraph2 } from "baseui/typography"
import { LinkButton } from "./Button"
const THIRD = 8 / 3
export const Welcome = () => {
  return (
    <Layout heading="Have you been underpaid?">
      <Cell span={[4, THIRD, 4]}>
        <Paragraph2>
          Enter your details and we will give you a quick idea of whether wage
          theft might have occured.
        </Paragraph2>
      </Cell>
      <Cell span={[4, THIRD, 4]}>
        <Paragraph2>
          If there is a good chance wage theft has occured, enter some more
          details and we will have a case manager look at your case.
        </Paragraph2>
      </Cell>
      <Cell span={[4, THIRD, 4]}>
        <Paragraph2>
          After our case manager has looked at your situation we will be in
          touch on how to proceed.
        </Paragraph2>
      </Cell>
      <Cell span={[4, 8, 12]}>
        <LinkButton
          to="/quick-estimate"
          overrides={{
            BaseButton: { style: { marginLeft: "auto", marginRight: "auto" } }
          }}
        >
          Get started
        </LinkButton>
      </Cell>
    </Layout>
  )
}
