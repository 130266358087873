import React from "react"
import { FormControl, formControlPadding } from "../FormControl"
import { Input } from "../Input"
import { InputOverrides } from "baseui/input"
import { useStyletron } from "baseui"

const DollarSign = () => {
  const [css] = useStyletron()
  return (
    <span
      className={css({
        display: "flex",
        alignItems: "center"
      })}
    >
      $
    </span>
  )
}
const inputOverrides: InputOverrides = {
  InputContainer: { style: { paddingLeft: "10px", width: "186px" } },
  Input: {
    style: {
      paddingLeft: "4px"
    }
  },
  Before: DollarSign
}
export const HourlyPayFormControl = () => {
  const [pay, setPay] = React.useState("")
  let error
  if (pay && !pay.match(/^\d+(\.\d*)?$/)) {
    error = "Please enter your pay as a decimal number (e.g 24.09)"
  }
  return (
    <FormControl
      error={error}
      overrides={formControlPadding}
      label="Hourly rate of pay"
    >
      <Input
        error={!!error}
        onChange={({ currentTarget }) => setPay(currentTarget.value)}
        value={pay}
        overrides={inputOverrides}
        id="hourly-pay"
        type="tel"
      />
    </FormControl>
  )
}
