import React from "react"
import { Client as Styletron } from "styletron-engine-atomic"
import { Welcome } from "./Welcome"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider, createTheme, lightThemePrimitives } from "baseui"
import { HashRouter as Router, Switch, Route } from "react-router-dom"
import { QuickEstimateForm } from "./QuickEstimateForm/QuickEstimateForm"
import { white, black, blazeOrange } from "./colors"
const engine = new Styletron()

const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "Helvetica,Arial,sans-serif"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: blazeOrange,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: blazeOrange,
      inputFill: white
    }
  }
)

function App() {
  return (
    <>
      <Router>
        <StyletronProvider value={engine}>
          <BaseProvider theme={theme}>
            <Switch>
              <Route path="/" exact>
                <Welcome />
              </Route>
              <Route path="/quick-estimate" exact>
                <QuickEstimateForm />
              </Route>
            </Switch>
          </BaseProvider>
        </StyletronProvider>
      </Router>
    </>
  )
}

export default App
